import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Menu from "./menu"
import $ from "jquery"
import Border from "./border"
import { ILink } from "../model/Links"

interface MobileMenuState {
  width: number
  height: number
}
interface MobileMenuProps {
  darkMode?: boolean
  links: ILink[]
}
export default class MobileMenu extends Component<
  MobileMenuProps,
  MobileMenuState
> {
  constructor(props: MobileMenuProps) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    $(".mobile-header").on("touchmove", function(e) {
      e.preventDefault()
    })
    $("#mobile-menu .dropdown-menu").hover(
      function() {
        $(this)
          .parent()
          .addClass("show")
        $(this).addClass("show")
      },
      function() {
        $(this)
          .parent()
          .removeClass("show")
        $(this).removeClass("show")
      },
    )
    $(".menu-close").click(function() {
      $(".mobile-header").removeClass("show")
    })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    return (
      <>
        <Border position="fixed" mobile />
        <Container className="h-100">
          <Row className="h-100">
            <Col xs="2" sm="6" md="6">
              <Menu
                showLogo={true}
                id="mobile-menu"
                darkMode={this.props.darkMode}
                links={this.props.links}
              />
            </Col>
            <Col xs="1" sm="2" md="4" className="d-flex justify-content-center">
              <button className="menu-close" />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
