import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Videos from "../model/Video"
import Plyr from "plyr"
import {
  isEdge,
  isChrome,
  isChromium,
  isFirefox,
  isSafari,
  isIE,
  isEdgeChromium,
} from "react-device-detect"
interface VideoPlayerProps {
  step: number
  darkMode: boolean
}
interface VideoPlayerState {
  width: number
  height: number
  ready: boolean
  darkMode: boolean
  isIE: boolean
  step: {
    startTime: number
    endTime: number
    playbackRate: number
    image: string
  }
}
export default class VideoPlayer extends Component<
  VideoPlayerProps,
  VideoPlayerState
> {
  constructor(props: VideoPlayerProps) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      ready: false,
      step: Videos[this.props.step],
      darkMode: this.props.darkMode,
      isIE: false,
    }
  }
  player: Plyr
  componentDidMount() {
    this.player = new Plyr("#hero-player", {
      controls: [],
      clickToPlay: false,
      duration: 30,
      fullscreen: {
        enabled: false,
      },
    })
    this.player.source = {
      type: "video",
      title: "Example title",
      sources: [
        {
          src: this.props.darkMode
            ? "../../videos/satalia_hero_bw.mp4"
            : "../../videos/satalia_hero.mp4",
          type: "video/mp4",
        },
      ],
    }
    this.player.currentTime = this.state.step.startTime
    this.player.on("ready", () => {
      setTimeout(() => {
        this.setState({ ready: true }, () => {
          this.player.muted = true
          this.player.play()
        })
      }, 2000)
    })

    this.player.on("timeupdate", () => {
      if (this.player.currentTime >= this.state.step.endTime) {
        this.player.pause()
      }
    })
    if (
      !isChrome &&
      !isFirefox &&
      !isSafari &&
      !isChromium &&
      !isEdgeChromium &&
      (isEdge || isIE)
    ) {
      this.setState({ isIE: true })
    }
  }
  getStepImage = (images) => {
    let step = this.state.step.image
    if (this.props.darkMode) {
      step += "dark"
    }
    let image = images[step.replace("-", "")]
    return image ? image.childImageSharp.fixed : null
  }
  componentDidUpdate(prevProps: VideoPlayerProps) {
    if (prevProps.darkMode !== this.props.darkMode) {
      this.setState({ ready: false }, () => {
        this.player.source = {
          type: "video",
          sources: [
            {
              src: this.props.darkMode
                ? "../../videos/satalia_hero_bw.mp4"
                : "../../videos/satalia_hero.mp4",
              type: "video/mp4",
            },
          ],
        }
        this.player.currentTime = this.state.step.startTime
        setTimeout(() => {
          this.setState({ ready: true }, () => {
            this.player.muted = true
            this.player.play()
          })
        }, 2000)
      })
    }
  }
  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              step1: file(relativePath: { eq: "steps/step-1.jpg" }) {
                publicURL
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              step2: file(relativePath: { eq: "steps/step-2.jpg" }) {
                publicURL
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              step2dark: file(relativePath: { eq: "steps/step-2-dark.jpg" }) {
                publicURL
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              step3: file(relativePath: { eq: "steps/step-3.jpg" }) {
                publicURL
                childImageSharp {
                  fixed(width: 1920) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={(data) => {
            let image = this.getStepImage(data)
            if (!image) return
            return (
              <Img
                loading="eager"
                objectPosition="0 0"
                fixed={this.getStepImage(data)}
                objectFit="unset"
                alt="satalia-video-placeholder"
                style={{
                  position: "absolute",
                  opacity: this.state.ready === true ? 0 : 1,
                  height: "calc(80vh)",
                  minHeight: "514px",
                  width: "100vw",
                  transition: "none",
                }}
              />
            )
          }}
        />
        <div
          className={this.state.isIE ? "ie-video" : ""}
          style={{
            opacity: this.state.ready === true ? 1 : 0,
            transition: "none",
          }}
        >
          <video
            id="hero-player"
            playsInline
            controls
            autoPlay={false}
            muted
            className="ie-video"
            style={{ opacity: 0 }}
          ></video>
        </div>
      </>
    )
  }
}
