import React, { Component, ReactNode } from "react"
import $ from "jquery"

interface CarouselProps {
  activeIndex: number
  children: React.ReactNode
  autoPlay?: boolean
  navigationEvent?: Function
}
interface ChildProps {
  children: React.ReactNode
  className?: string
}
export const Tabs = (props: ChildProps) => (
  <ul className="st-carousel__tabs">{props.children}</ul>
)

export const Navigations = (props: ChildProps) => (
  <ul className="st-carousel__navigations">{props.children}</ul>
)

export const Tab = (props: ChildProps) => (
  <div className={"st-carousel__tab-container"}>
    <li className={"st-carousel__tab"}>{props.children}</li>
  </div>
)

export const Contents = (props: ChildProps) => (
  <ul className={`st-carousel__contents ${props.className}`}>
    {props.children}
  </ul>
)

export const Content = (props: ChildProps) => (
  <li className="st-carousel__content">{props.children}</li>
)

export const Title = (props: ChildProps) => (
  <h3 className="st-carousel__title font-weight-medium">{props.children}</h3>
)

export default class Carousel extends Component<CarouselProps> {
  TAB_SELECTOR = ".st-carousel__tab"
  TAB_COLUMN_SELECTOR = ".st-carousel__tab-column"
  TAB_ACTIVE_SELECTOR = ".st-carousel__tab--active"
  CONTENT_SELECTOR = ".st-carousel__content"
  CONTENT_ACTIVE_SELECTOR = ".st-carousel__content--active"
  NAVIGATION_SELECTOR = ".st-carousel__navigation"

  TAB_NAME = "st-carousel__tab"
  TAB_ACTIVE_NAME = "st-carousel__tab--active"
  CONTENT_NAME = "st-carousel__content"
  CONTENT_ACTIVE_NAME = "st-carousel__content--active"
  NAVIGATION_NAME = "st-carousel__navigation"
  NAVIGATION_DEACTIVE_NAME = "st-carousel__navigation--disabled"

  tabs: JQuery<HTMLElement> = null
  tabColumns: JQuery<HTMLElement> = null
  navigations: JQuery<HTMLElement> = null
  contents: JQuery<HTMLElement> = null
  activeIndex: number = 0
  slide: number = 0
  slideTimeout: { pause: Function; play: Function; stop: Function } = null

  componentDidMount() {
    let vm = this
    this.tabs = this._getItems(this.TAB_SELECTOR)
    this.tabColumns = this._getItems(this.TAB_COLUMN_SELECTOR)
    this.navigations = this._getItems(this.NAVIGATION_SELECTOR)
    this.contents = this._getItems(this.CONTENT_SELECTOR)
    this.activeIndex = this.props.activeIndex
    this.slideTimeout = this.Timer(function() {
      vm.navigations.removeClass(vm.NAVIGATION_DEACTIVE_NAME)
      if (vm.tabs.length - 1 === vm.activeIndex) {
        vm._setActiveItem(0)
        vm.navigations.eq(0).addClass(vm.NAVIGATION_DEACTIVE_NAME)
      } else {
        vm._setActiveItem(vm.activeIndex + 1)
        if (vm.activeIndex === vm.tabs.length - 1) {
          vm.navigations.eq(1).addClass(vm.NAVIGATION_DEACTIVE_NAME)
        }
      }
    }, 10000)
    if (this.props.autoPlay === false) {
      this.slideTimeout.stop()
    } else {
      this.tabs.mouseenter(() => {
        $(".progress-bar").addClass("pause")
        this.slideTimeout.pause()
      })
      this.tabs.mouseleave(() => {
        $(".progress-bar").removeClass("pause")
        this.slideTimeout.play()
      })
      this.contents.mouseenter(() => {
        $(".progress-bar").addClass("pause")
        this.slideTimeout.pause()
      })
      this.contents.mouseleave(() => {
        $(".progress-bar").removeClass("pause")
        this.slideTimeout.play()
      })
    }
    this._setActiveItem(this.activeIndex)

    this.tabs.click(function() {
      vm.slide = 0
      vm._setActiveItem(vm.tabs.index(this))
    })

    this._initNav()

    window.addEventListener("resize", this.updateWindowDimensions)
  }
  UNSAFE_componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }
  UNSAFE_componentWillReceiveProps(newProps: CarouselProps) {
    if (this.activeIndex !== newProps.activeIndex)
      this._setActiveItem(newProps.activeIndex)
  }
  updateWindowDimensions = () => {
    this.tabColumns.css("transform", "translate(0,0)")
    if (window.innerWidth > 768 && this.props.autoPlay === true) {
      this.contents.css("transform", "translate(0,0)")
    }

    this._setActiveItem(this.activeIndex)
  }

  _initNav = () => {
    let vm = this

    this.navigations.click(function() {
      if ($(this).hasClass(vm.NAVIGATION_DEACTIVE_NAME)) return

      vm.navigations.removeClass(vm.NAVIGATION_DEACTIVE_NAME)

      let navigate = $(this).data("navigate")

      if (navigate) {
        if (navigate === "next" && vm.activeIndex < vm.tabs.length - 1) {
          vm._setActiveItem(vm.activeIndex + 1)
          if (vm.activeIndex === vm.tabs.length - 1) {
            $(this).addClass(vm.NAVIGATION_DEACTIVE_NAME)
          }
        }
        if (navigate === "previous" && vm.activeIndex > 0) {
          vm._setActiveItem(vm.activeIndex - 1)
          if (vm.activeIndex === 0) {
            $(this).addClass(vm.NAVIGATION_DEACTIVE_NAME)
          }
        }
      }
    })
  }

  _getItems = (selector: string) => {
    return $(selector)
  }

  _setActiveItem = (index: number) => {
    this.activeIndex = index
    this.slideTimeout.stop()
    $(".progress").remove()
    if (this.tabs.length > 0) {
      this.tabs.removeClass(this.TAB_ACTIVE_NAME)
      this.tabs.eq(index).addClass(this.TAB_ACTIVE_NAME)
      this.tabs.eq(index).append(`
        <div class="progress">
          <div
            role="progressbar"
            class="progress-bar"
          >
            <span class="sr-only"></span>
          </div>
        </div>
      `)
    }
    this.contents.removeClass("opacity-1")
    let translate = `translate(${-index * 100}%,0)`
    this.contents.css("transform", translate)
    this.contents.eq(index).addClass("opacity-1")
    if (window.innerWidth < 768) {
      this.tabColumns.css("transform", translate)
    }
    if (this.props.autoPlay) {
      this.slide = 0
      this._slideStart()
    } else {
      this.slide = 0
      $(".progress-bar").addClass("w-100")
    }
    if (this.props.navigationEvent) this.props.navigationEvent(this.activeIndex)
  }

  _slideStart = () => {
    $(".progress-bar").addClass("loading")
    this.slideTimeout.play()
  }

  Timer = (callback: Function, delay: number) => {
    let timerId: number
    let start: number
    let remaining = delay

    const pause = function() {
      window.clearTimeout(timerId)
      remaining -= Date.now() - start
    }

    const play = function() {
      start = Date.now()
      window.clearTimeout(timerId)
      timerId = window.setTimeout(callback, remaining)
    }
    const stop = function() {
      window.clearTimeout(timerId)
      remaining = delay
    }
    return { pause, play, stop }
  }

  static Title = Title
  static Tabs = Tabs
  static Tab = Tab
  static Contents = Contents
  static Content = Content
  static Navigations = Navigations
  render() {
    return <div className="st-carousel">{this.props.children}</div>
  }
}
