import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
interface ImageProsp {
  path: string
  isSquare: boolean
  alt?: string
}
export default class Image extends React.Component<ImageProsp> {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            images: allGoogleStorage(
              filter: { name: { regex: "/jpeg|jpg|png|gif|svg/" } }
            ) {
              edges {
                node {
                  baseUrl
                  name
                  signedUrl
                  id
                  children {
                    ... on ImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
            placeholder: file(relativePath: { eq: "placeholder.png" }) {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => {
          let image = data.images.edges.find((i: any) => {
            return i.node.name.includes(this.props.path)
          })
          if (image && image.node.extension === "svg") {
            return <img src={image.node.publicURL} alt={this.props.alt} />
          }
          if (!image) {
            image = data.placeholder.childImageSharp.fluid
          } else {
            return (
              <img
                src={`https://storage.googleapis.com/satalia-page-images/${image.node.name}`}
                alt={this.props.alt}
              />
            )
            //image = image.node.children.fluid
          }
          return (
            <Img
              loading="eager"
              fluid={image}
              alt={this.props.alt}
              style={{ paddingBottom: this.props.isSquare ? "33.2%" : 0 }}
            />
          )
        }}
      />
    )
  }
}
