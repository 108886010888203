import { Link, graphql, StaticQuery } from "gatsby"
import React, { useEffect } from "react"
import $ from "jquery"
import { ILink, MenuLinks } from "../model/Links"
interface MenuProps {
  showLogo?: boolean
  darkMode?: boolean
  id: string
  links?: ILink[]
}
const Menu = (props: MenuProps) => {
  const menu = props.links ? props.links : MenuLinks
  useEffect(() => {
    // Update the document title using the browser API
    $("#content-menu .dropdown-menu").mouseenter(function() {
      $(this).addClass("show")
    })
    $("#content-menu .dropdown-menu").mouseleave(function() {
      $(this).removeClass("show")
    })
  })
  return (
    <nav className="navbar" id={props.id}>
      {props.showLogo ? (
        <Link to="/" className="navbar-brand" title="Satalia">
          <StaticQuery
            query={graphql`
              query {
                default: file(relativePath: { eq: "logo.svg" }) {
                  publicURL
                }
                light: file(relativePath: { eq: "logo-light.svg" }) {
                  publicURL
                }
              }
            `}
            render={(data) => {
              return (
                <img
                  width="75"
                  alt="satalia-logo"
                  src={
                    props.darkMode === true
                      ? data.light.publicURL
                      : data.default.publicURL
                  }
                />
              )
            }}
          />
        </Link>
      ) : null}
      <ul className="navbar-nav">
        {menu.map((link, index) => (
          <li className={`nav-item ${link.class}`} key={link.label}>
            {link.isDropdown === true ? (
              <div className="dropdown-menu nav-link">
                {link.label}
                <ul className="dropdown-menu__items">
                  {link.dropdownLinks.map((link) => (
                    <li className="dropdown-menu__item" key={link.label}>
                      {link.isExternal ? (
                        <a
                          className="nav-link"
                          title={link.label}
                          href={link.to}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link.label}
                        </a>
                      ) : (
                        <Link
                          className="nav-link"
                          to={link.to}
                          title={link.label}
                        >
                          {link.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : menu.length - 1 === index ? (
              <Link
                className="nav-link"
                to={link.to}
                style={{ marginBottom: 0 }}
                title={link.label}
              >
                {link.label}
              </Link>
            ) : (
              <Link className="nav-link" to={link.to} title={link.label}>
                {link.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu
