import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Plyr from "plyr"
interface ImageSectionProps {
  description: React.ReactNode
  video?: string
  image: string
  content: React.ReactNode
  align?: string
  customClass?: string
  aos?: string
}
export default class ImageSection extends Component<ImageSectionProps> {
  componentDidMount() {
    const player = new Plyr("#player", {
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "volume",
        "airplay",
        "fullscreen",
      ],
      youtube: {
        rel: 0,
        modestbranding: 1,
      },
    })
    player.on("play", (event) => {
      if (event.detail.plyr.currentTime < 104) player.currentTime = 104
    })
  }
  getImage = (images) => {
    let image = images.services.edges.find(
      (x) => x.node.name === this.props.image,
    )
    if (!image) {
      image = images.placeholder
      return image
    }
    return image.node
  }
  render() {
    return (
      <>
        <Row>{this.props.description}</Row>
        <Row className="justify-content-lg-end">
          <Col xs="3" sm="8" md="7" lg="8">
            <div
              className="st-block st-block--has-top-padding"
              data-aos={this.props.aos}
            >
              <StaticQuery
                query={graphql`
                  query {
                    services: allFile(
                      filter: { relativeDirectory: { eq: "services" } }
                    ) {
                      edges {
                        node {
                          id
                          relativeDirectory
                          childImageSharp {
                            fluid(maxWidth: 1920) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                          publicURL
                          name
                          extension
                        }
                      }
                    }
                    placeholder: file(relativePath: { eq: "placeholder.png" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                `}
                render={(data) => {
                  let image = this.getImage(data)
                  if (this.props.video) {
                    return (
                      <div
                        id="player"
                        data-plyr-provider="youtube"
                        data-plyr-embed-id="hndFmYKtysY"
                        data-poster={image ? image.publicURL : ""}
                      ></div>
                    )
                  }
                  if (!image) return null
                  return (
                    <Img
                      loading="eager"
                      fluid={this.getImage(data).childImageSharp.fluid}
                    />
                  )
                }}
              />
            </div>
          </Col>
          <Col
            xs="3"
            sm="6"
            md="3"
            lg="4"
            className={this.props.customClass ? this.props.customClass : ""}
          >
            <div
              className={`st-block ${
                this.props.align && this.props.align === "end"
                  ? " d-flex align-items-end"
                  : ""
              }`}
            >
              {this.props.content}
            </div>
          </Col>
        </Row>
      </>
    )
  }
}
