import React, { ReactNode, Component } from "react"
import { Row, Col, Container } from "react-bootstrap"
interface LayoutProps {
  position: ReactNode
  footer?: boolean
  mobile?: boolean
}

interface LayoutState {
  width: number
  height: number
}

export default class Layout extends Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  renderCols = () => {
    let classes = []
    if (this.props.mobile) {
      classes[0] = "opacity-1"
      classes[1] = "opacity-1 opacity-md-0"
      classes[2] = "opacity-1 opacity-md-0"
      classes[3] = "opacity-0"
      classes[4] = "opacity-0"
      classes[5] = "opacity-1"
      classes[6] = "opacity-0"
      classes[7] = "opacity-0"
      classes[8] = "opacity-0"
      classes[9] = "opacity-1"
      classes[10] = "opacity-0"
      classes[11] = "opacity-0"
    } else if (this.props.footer) {
      classes[0] = "opacity-1"
      classes[1] = "opacity-sm-0 opacity-md-1"
      classes[2] = "opacity-1 opacity-sm-1 opacity-md-0"
      classes[3] = "opacity-0 opacity-md-1"
      classes[4] = "opacity-0"
      classes[5] = "opacity-0 opacity-sm-1"
      classes[6] = "opacity-0"
      classes[7] = "opacity-0  opacity-sm-1"
      classes[8] = "opacity-0"
      classes[9] = "opacity-1 opacity-lg-1"
      classes[10] = "opacity-0"
      classes[11] = "opacity-1"
    }
    return [
      <Col xs="1" key="365-col-1" className={classes[0]}></Col>,
      <Col xs="1" key="365-col-2" className={classes[1]}></Col>,
      <Col xs="1" key="365-col-3" className={classes[2]}></Col>,
      this.state.width >= 768
        ? [
            <Col xs="1" key="768-col-1" className={classes[3]}></Col>,
            <Col xs="1" key="768-col-2" className={classes[4]}></Col>,
            <Col xs="1" key="768-col-3" className={classes[5]}></Col>,
            <Col xs="1" key="768-col-4" className={classes[6]}></Col>,
            <Col xs="1" key="768-col-5" className={classes[7]}></Col>,
          ]
        : null,
      this.state.width >= 1024
        ? [
            <Col xs="1" key="1024-col-1" className={classes[8]}></Col>,
            <Col xs="1" key="1024-col-2" className={classes[9]}></Col>,
          ]
        : null,
      this.state.width >= 1366
        ? [
            <Col xs="1" key="1366-col-1" className={classes[10]}></Col>,
            <Col xs="1" key="1366-col-2" className={classes[11]}></Col>,
          ]
        : null,
    ]
  }
  render() {
    return (
      <Container
        className={`position-${this.props.position} h-100 layout-container`}
      >
        <Row className="h-100 layout-border">{this.renderCols()}</Row>
      </Container>
    )
  }
}
