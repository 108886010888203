import React, { Component } from "react"
interface TooltipProps {
  tooltipText: string
  action?: () => {}
  actionCompleteText?: string
}
interface TooltipState {
  className: string
}
export default class Tooltip extends Component<TooltipProps, TooltipState> {
  tooltip = React.createRef<HTMLDivElement>()
  constructor(props: TooltipProps) {
    super(props)
    this.state = {
      className: "st-tooltip",
    }
  }
  componentDidMount() {}
  render() {
    return (
      <div
        ref={this.tooltip}
        className={this.state.className}
        onClick={this.props.action}
        onMouseEnter={() =>
          this.setState({ className: "st-tooltip st-tooltip--show" })
        }
        onMouseLeave={() => this.setState({ className: "st-tooltip" })}
      >
        {this.props.children}
        <span className="st-tooltip__text">{this.props.tooltipText}</span>
      </div>
    )
  }
}
