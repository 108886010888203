import React, { Component } from "react"
import { FormInput, FormControlElement } from "../model/Form"
import { Col, Form } from "react-bootstrap"
import { Link } from "gatsby"
import Select from "react-select"
import $ from "jquery"
import DatePicker from "react-datepicker"
import moment from "moment"
const validate = require("validate.js")

interface FormProps {
  inputs: FormInput[]
  name: string
  action: string
  darkMode?: boolean
}
interface FormState {
  inputs: FormInput[]
  status: boolean
  isPost: boolean
  dateRequest: Date
}

export default class FormComponent extends Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props)
    this.state = {
      inputs: this.props.inputs,
      isPost: false,
      status: false,
      dateRequest: new Date(),
    }
  }

  inputChange = (event: React.ChangeEvent<FormControlElement>) => {
    let formInputs: FormInput[] = [...this.state.inputs]
    let target = event.target
    let input = formInputs.find((x) => x.name === target.name)
    input.value = target.value
    input.error = null
    this.setState({ inputs: formInputs })
  }
  checboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let formInputs: FormInput[] = [...this.state.inputs]
    let target = event.target
    let input = formInputs.find((x) => x.name === target.name)
    input.value = target.checked
    input.error = null
    this.setState({ inputs: formInputs })
  }

  dateChange = (name: string, value: Date) => {
    let formInputs: FormInput[] = [...this.state.inputs]
    let input = formInputs.find((x) => x.name === name)
    input.value = moment(value).format("DD/MM/YYYY")
    input.error = null
    this.setState({ inputs: formInputs, dateRequest: value })
  }

  selectChange = (value: any, actionMeta: any) => {
    let formInputs: FormInput[] = [...this.state.inputs]
    let input = formInputs.find((x) => x.name === actionMeta.name)
    input.value = value.value
    input.error = null
    this.setState({ inputs: formInputs })
  }
  getBackgroundColor = (state: any) => {
    if (state.isFocused && state.isSelected) {
      return "#000"
    }
    if (state.isFocused) {
      return "RGBA(0,0,0,0.1)"
    }
    if (state.isSelected) {
      return "#000"
    }
    return "#FFF"
  }
  getBorderColor = (state: any) => {
    if (!state.hasValue) {
      return "#000"
    }
    return "#37f069"
  }
  getPadding = (state: any) => {
    if (state.isFocused && state.isSelected) {
      return 12
    }
    if (state.isFocused) {
      return 12
    }
    if (state.isSelected) {
      return 12
    }
    return 5
  }
  renderInput = (input: FormInput) => {
    if (input.type === "text" || input.type === "email") {
      return (
        <Form.Control
          type={input.type}
          placeholder={input.label}
          name={input.name}
          onChange={(event) => this.inputChange(event)}
          className={input.error ? "has-error" : null}
        />
      )
    }
    if (input.type === "select") {
      return (
        <Select
          value={input.options.find((x) => x.value === input.value)}
          onChange={(value, actionMeta) => this.selectChange(value, actionMeta)}
          className="select"
          name={input.name}
          classNamePrefix="select"
          placeholder={input.label}
          options={input.options}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              color: state.selectProps.menuColor,
              padding: 15,
              borderRadius: 0,
              borderWidth: 1,
              left: 2,
              fontSize: 15,
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: this.getBackgroundColor(state),
              paddingLeft: this.getPadding(state),
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: 18,
              lineHeight: 21,
            }),
            singleValue: (provided) => ({
              ...provided,
              fontSize: 18,
              lineHeight: 21,
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
            }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "transparent",
              borderWidth: "0 0 2px 0",
              borderRadius: 0,
              borderColor: this.getBorderColor(state),
              boxShadow: "none",
            }),
          }}
        />
      )
    }
    if (input.type === "textarea") {
      return (
        <Form.Control
          as="textarea"
          name={input.name}
          onChange={(event) => this.inputChange(event)}
          rows={14}
          placeholder={input.label}
          className={input.error ? "has-error" : null}
        />
      )
    }
    if (input.type === "checkbox") {
      return (
        <Form.Check
          type="checkbox"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.checboxChange(event)
          }
          name={input.name}
          custom
          label={input.label}
        />
      )
    }
    if (input.type === "date") {
      return (
        <DatePicker
          name={input.name}
          minDate={new Date()}
          placeholderText={input.label}
          onChange={(date: Date) => {
            this.dateChange(input.name, date)
          }}
          selected={this.state.dateRequest}
          onFocus={() => (input.focused = true)}
          onBlur={() => (input.focused = false)}
          value={input.value}
          className="form-control"
          wrapperClassName={input.value || input.focused ? "selected" : ""}
          closeOnScroll={true}
        />
      )
    }
  }

  formSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    let formInputs = [...this.state.inputs]
    let errorIndex = -1
    formInputs.map((x, index) => {
      const error = validate({ [x.name]: x.value }, { [x.name]: x.validation })
      if (error) {
        x.error = error[x.name][0]
        if (errorIndex < 0) errorIndex = index
      }
      return x
    })
    if (errorIndex > -1) {
      $(
        `form[name=${this.props.name}] input[name=${formInputs[errorIndex].name}]`,
      ).focus()
      this.setState({ inputs: formInputs })
    } else {
      let formData: any = {}
      formInputs.map((x) => {
        formData[x.name] =
          typeof x.value === "boolean" ? (x.value ? "1" : "0") : x.value
      })
      fetch(this.props.action, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) =>
          response.success
            ? this.setState({ isPost: true, status: true })
            : this.setState({ isPost: true, status: false }),
        )
    }
    // let target = event.target
    // form[target.name].value = target.value
    // setFormNewBusiness(form)
    event.preventDefault()
  }
  renderFooter = () => {
    if (this.state.isPost === false) {
      return (
        <Form.Row className="mb-0" key={this.props.name + "footer"}>
          <Col lg="4" md="3" sm="2" xs="3">
            <button
              type="submit"
              className={`st-btn ${this.props.darkMode ? "st-btn--light" : ""}`}
            >
              Submit
            </button>
          </Col>
        </Form.Row>
      )
    }
    if (this.state.isPost === true && this.state.status === false) {
      return (
        <Form.Row className="mb-0 form-status" key={this.props.name + "footer"}>
          <Col xs="3" sm="8" md="10" lg="12">
            <h3 className="d-flex align-items-center">
              <span className="form-status__icon">
                <i className="icon icon-wrong"></i>
              </span>
              Something went wrong
            </h3>

            <Form.Text>
              <p>
                We didn’t receive your message, please try again or reach us via
                connect@satalia.com
              </p>
            </Form.Text>
          </Col>
          <Col lg="4" md="3" sm="2" xs="3">
            <button
              type="submit"
              className={`st-btn mt-0 ${
                this.props.darkMode ? "st-btn--light" : ""
              }`}
            >
              Try again
            </button>
          </Col>
        </Form.Row>
      )
    }

    if (this.state.isPost === true && this.state.status === true) {
      return (
        <Form.Row className="mb-0 form-status">
          <Col xs="3" sm="8" md="10" lg="12">
            <h3 className="d-flex align-items-center">
              <span className="form-status__icon">
                <i className="icon icon-success"></i>
              </span>
              Success!
            </h3>
            <Form.Text>
              <p>Message sent successfully. We will reply as soon as we can.</p>
            </Form.Text>
          </Col>
          <Col lg="4" md="3" sm="2" xs="3">
            <Link to="/" title="Satalia">
              <button
                type="button"
                className={`st-btn mt-0 ${
                  this.props.darkMode ? "st-btn--light" : ""
                }`}
              >
                Home page
              </button>
            </Link>
          </Col>
        </Form.Row>
      )
    }
  }
  render() {
    const { inputs } = this.state
    return (
      <Form onSubmit={this.formSubmit} name={this.props.name}>
        <Form.Text className="form-validations">
          <p>All fields are required.</p>
        </Form.Text>
        <Form.Row key={this.props.name + "row"}>
          {inputs.map((input, index) => {
            let cols = []
            cols.push(
              <Col
                xs="3"
                sm={input.fullCol ? 8 : 4}
                md={input.fullCol ? 10 : 5}
                lg={input.fullCol ? 12 : 6}
                key={index + input.name + this.props.name}
                className={
                  input.type === "checkbox"
                    ? "mb-0"
                    : input.type === "textarea"
                    ? "form__textarea"
                    : ""
                }
              >
                <Form.Group controlId={input.name}>
                  {!input.floatingLabel && input.type !== "checkbox" ? (
                    <Form.Label>{input.label}</Form.Label>
                  ) : null}
                  {this.renderInput(input)}
                  {input.floatingLabel && input.type !== "checkbox" ? (
                    <Form.Label className="floating-label">
                      {input.label}
                    </Form.Label>
                  ) : null}
                  {input.error ? (
                    <Form.Text className="error-text">{input.error}</Form.Text>
                  ) : null}
                </Form.Group>
              </Col>,
            )
            if (input.fullRow) {
              cols.push(
                <Col
                  className="w-100 mb-0"
                  key={index + input.name + this.props.name + "fullrow"}
                ></Col>,
              )
            }
            return cols
          })}
        </Form.Row>
        <Form.Text>
          <p>
            Please read our{" "}
            <a
              className="iubenda-nostyle no-brand iubenda-embed"
              href="https://www.iubenda.com/privacy-policy/7785247"
              title="Privacy Policy "
            >
              Privacy Policy
            </a>{" "}
            to see how we use your personal information.
          </p>
        </Form.Text>
        {this.renderFooter()}
      </Form>
    )
  }
}
