import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import { Menu } from "../components"
import { MenuLinks } from "../model/Links"

interface HeadSectionProps {
  title: string
  subtitle: string
  description?: string
  children?: any
}
export default class HeadSection extends Component<HeadSectionProps> {
  render() {
    return (
      <Row className="justify-content-center">
        <Col className="d-none d-lg-block" lg="4">
          <div className="st-block st-block--head">
            <Menu links={MenuLinks} id="content-menu" />
          </div>
        </Col>
        {this.props.children ? this.props.children : null}
        {this.props.children ? (
          <Col className="d-none d-lg-block" lg="4"></Col>
        ) : null}
        {this.props.description ? (
          <Col xs="3" sm="4" md="5" lg="4">
            <div className="st-block st-block--head">
              <h1 dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
              <h2
                className="font-weight-normal"
                dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
              ></h2>
            </div>
          </Col>
        ) : (
          <Col xs="3" sm="8" md="10" lg="8">
            <div className="st-block st-block--head">
              <h1 dangerouslySetInnerHTML={{ __html: this.props.title }}></h1>
              <h2
                className="font-weight-normal"
                dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
              ></h2>
            </div>
          </Col>
        )}
        {this.props.description ? (
          <Col xs="3" sm="4" md="5" lg="4" className="z-index-1001">
            <div
              className="st-block st-block--head st-block--head-xs-0 text-inherit"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            ></div>
          </Col>
        ) : null}
      </Row>
    )
  }
}
