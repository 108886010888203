import React, { Component } from "react"
import $ from "jquery"
interface ScrollableProps {
  itemSelector?: string
  section?: string
  leftIndex?: number
}
export default class Scrollable extends Component<ScrollableProps> {
  componentDidMount() {
    const vm = this
    const slider = $(vm.props.section + " .st-scroll")
    let isDown = false
    let startX = 0
    let startEnd = 0
    let scrollLeft = 0
    let animationScroll = true
    slider.mousemove(function(e) {
      if (!isDown || e.which === 2) return
      e.preventDefault()
      startEnd = e.pageX - $(this).offset().left
      const walk = (startEnd - startX) * 3
      $(this).scrollLeft(scrollLeft - walk)
    })

    slider.mousedown(function(e) {
      if (e.which === 2) return
      isDown = true
      startX = e.pageX - $(this).offset().left
      scrollLeft = $(this).scrollLeft()
    })
    slider.mouseleave(function() {
      isDown = false
    })
    slider.mouseup(function() {
      isDown = false
      if (scrollLeft !== slider.scrollLeft()) {
        let itemIndex: number = Math.round(
          slider.scrollLeft() / $(vm.props.itemSelector).width(),
        )
        slider.animate(
          {
            scrollLeft:
              $(vm.props.itemSelector).width() * itemIndex + itemIndex * 30,
          },
          300,
        )
      }
    })
    slider.on("touchend", function() {
      isDown = false
      scrollLeft = $(this).scrollLeft()
      animationScroll = false
    })
    slider.scroll(function() {
      clearTimeout($.data(this, "scrollCheck"))
      $.data(
        this,
        "scrollCheck",
        setTimeout(function() {
          if ($(window).width() < 1024 && animationScroll === true) {
            return
          }
          if (isDown === false) {
            let itemIndex: number = Math.round(
              slider.scrollLeft() / $(vm.props.itemSelector).width(),
            )
            slider.animate(
              {
                scrollLeft:
                  $(vm.props.itemSelector).width() * itemIndex + itemIndex * 30,
              },
              300,
              function() {
                scrollLeft = slider.scrollLeft()
                animationScroll = true
              },
            )
          }
        }, 100),
      )
    })
    let leftIndex = vm.props.leftIndex ? vm.props.leftIndex : 0
    slider.scrollLeft(
      leftIndex === 0
        ? 0
        : $(vm.props.itemSelector).width() * leftIndex + leftIndex * 30,
    )
  }

  render() {
    return <div>{this.props.children}</div>
  }
}
