import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import Border from "./border"
import { SocialLinks } from "../model/Links"
import { FooterLogoQuery } from "../graphql"
import Scrollable from "./scrollable"
const Footer = (props: { darkMode?: boolean; page?: string }) => {
  const data: FooterLogoQuery = useStaticQuery(graphql`
    query FooterLogo {
      default: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      light: file(relativePath: { eq: "logo-light.svg" }) {
        publicURL
      }
    }
  `)
  let loader = function() {
    let s = document.createElement("script")
    let tag = document.getElementsByTagName("script")[0]
    s.src = "https://cdn.iubenda.com/iubenda.js"
    tag.parentNode.insertBefore(s, tag)
  }
  useEffect(() => {
    loader()
  }, [])
  return (
    <footer className="">
      <Border position="absolute" footer />
      <Container fluid className="d-sm-none">
        <Row className="justify-content-center">
          <Col xs="3" className="footer__description container-max">
            <div className="st-block st-block--transparent">
              <h2>Get in touch</h2>
              <p>
                We operate globally, with the team distributed across the UK,
                Lithuania, Greece and Austria.
                <br />
                <br />
                To talk to us about how artificial intelligence can impact your
                business{" "}
                <Link
                  className="learn-more d-inline-block mt-0"
                  title="careers"
                  to={
                    props.page === "careers"
                      ? "/contact#open-positions"
                      : "/contact"
                  }
                >
                  get in touch.
                </Link>
              </p>
            </div>
          </Col>
          <Col xs="3">
            <Scrollable itemSelector=".footer__contact" section="footer">
              <Row className="footer__contacts st-scroll">
                <div className="scroll-padding"></div>
                <div className="footer__contact">
                  <p className="font-weight-medium">London</p>
                  <p>
                    <a
                      href="mailto:hello@satalia.com"
                      rel="nofollow noreferrer"
                      title="hello@satalia.com"
                    >
                      hello@satalia.com
                    </a>
                  </p>
                  <br />
                  <p>Greater London House,</p>
                  <p>180 Hampstead Road,</p>
                  <p>London, NW1 7AW,</p>
                  <p>United Kingdom</p>
                  <a
                    className="learn-more"
                    href="https://goo.gl/maps/V3hmc4pjTVLb9GMU6"
                    target="blank"
                    rel="nofollow noreferrer"
                    title="Greater London House, 180 Hampstead Road, London, NW1 7AW, United Kingdom"
                  >
                    Get directions
                  </a>
                </div>
                <div className="footer__contact">
                  <p className="font-weight-medium">Kaunas</p>
                  <p>
                    <a
                      href="mailto:labas@satalia.com"
                      rel="nofollow noreferrer"
                      title="labas@satalia.com"
                    >
                      labas@satalia.com
                    </a>
                  </p>
                  <br />
                  <p>Jonavos g. 30,</p>
                  <p>Kaunas, LT-44262,</p>
                  <p>Lithuania</p>
                  <a
                    className="learn-more"
                    href="https://goo.gl/maps/AzzJj9BvPJR8aFKC9"
                    target="blank"
                    rel="nofollow noreferrer"
                    title="Jonavos g. 30, Kaunas, LT-44262, Lithuania"
                  >
                    Get directions
                  </a>
                </div>
                <div className="scroll-padding-right"></div>
              </Row>
            </Scrollable>
          </Col>
          <Col xs="3" className="container-max footer__socials">
            <Row>
              <Col xs="2" className="footer__socials--links">
                <ul className="footer__links">
                  {SocialLinks.map((link) => (
                    <li
                      className={`footer__links-item ${link.class}`}
                      key={link.label}
                    >
                      <a
                        className="footer__links-link"
                        href={link.to}
                        rel="nofollow noreferrer"
                        title={link.label}
                        target="_blank"
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs="3" className="container-max">
            <Row>
              <Col xs="3">
                <p className="text-inherit">
                  © 2008 - {new Date().getFullYear()} NPComplete Ltd (Trading as
                  Satalia). All Rights Reserved. NPComplete Ltd is a Company in
                  England and Wales. Registered number: 06759250
                </p>
              </Col>
              <Col xs="3" className="footer__privacy ">
                <ul className="footer__links">
                  <li className="footer__links-item">
                    <a
                      className="footer__links-link iubenda-nostyle no-brand iubenda-embed"
                      href="https://www.iubenda.com/privacy-policy/7785247"
                      title="Privacy Policy "
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="footer__links-item">
                    <a
                      className="footer__links-link iubenda-nostyle no-brand iubenda-embed"
                      href="https://www.iubenda.com/privacy-policy/7785247/cookie-policy"
                    >
                      Cookie Policy
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="d-none d-sm-block">
        <Row>
          <Col xs="3" sm="8" md="10" lg="12">
            <div className="st-block st-block--transparent">
              <h2>Get in touch</h2>
              <Row>
                <Col sm="6" md="4" lg="4" className="order-md-1">
                  <p>
                    We operate globally, with the team distributed across the
                    UK, Lithuania, Greece and Austria.
                  </p>
                  <br />
                  <p>
                    To talk to us about how artificial intelligence can impact
                    your business,{" "}
                    <Link
                      className="learn-more d-inline-block mt-0"
                      title="Careers"
                      to={
                        props.page === "careers"
                          ? "/contact#open-positions"
                          : "/contact"
                      }
                    >
                      get in touch.
                    </Link>
                  </p>
                </Col>
                <Col sm="2" md="10" lg="12" className="order-md-4 order-lg-4">
                  <ul className="footer__links footer__links-social">
                    {SocialLinks.map((link) => (
                      <li
                        className={`footer__links-item ${link.class} footer__links--xl-padding`}
                        key={link.label}
                      >
                        <a
                          className="footer__links-link"
                          href={link.to}
                          rel="nofollow noreferrer"
                          title={link.label}
                          target="_blank"
                        >
                          {link.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col
                  sm="8"
                  md="6"
                  lg="6"
                  className="footer__contacts order-md-2 order-lg-2 d-md-none"
                >
                  <Row>
                    <Col md="5" lg="4">
                      <div className="footer__contact">
                        <p className="font-weight-medium">London</p>
                        <p>
                          <a
                            href="mailto:hello@satalia.com"
                            title="hello@satalia.com"
                            rel="nofollow noreferrer"
                          >
                            hello@satalia.com
                          </a>
                        </p>
                        <br />
                        <p className="footer__contact-adress">
                          Greater London House,
                        </p>
                        <p className="footer__contact-adress">
                          180 Hampstead Road,
                        </p>
                        <p className="footer__contact-adress">
                          London, NW1 7AW,
                        </p>
                        <p className="footer__contact-adress">United Kingdom</p>
                        <a
                          className="learn-more"
                          href="https://goo.gl/maps/V3hmc4pjTVLb9GMU6"
                          target="blank"
                          rel="nofollow noreferrer"
                          title="Greater London House, 180 Hampstead Road, London, NW1 7AW, United Kingdom"
                        >
                          Get directions
                        </a>
                      </div>
                    </Col>
                    <Col md="5" lg="4">
                      <div className="footer__contact">
                        <p className="font-weight-medium">Kaunas</p>
                        <p>
                          <a
                            href="mailto:labas@satalia.com"
                            title="labas@satalia.com"
                            rel="nofollow noreferrer"
                          >
                            labas@satalia.com
                          </a>
                        </p>
                        <br />
                        <p className="footer__contact-adress">Jonavos g. 30,</p>
                        <p className="footer__contact-adress">
                          Kaunas, LT-44262,
                        </p>
                        <p className="footer__contact-adress">Lithuania</p>
                        <a
                          className="learn-more"
                          href="https://goo.gl/maps/AzzJj9BvPJR8aFKC9"
                          target="blank"
                          title="Jonavos g. 30, Kaunas, LT-44262, Lithuania"
                          rel="nofollow noreferrer"
                        >
                          Get directions
                        </a>
                      </div>
                    </Col>
                    <Col md="5" lg="4">
                      <div className="footer__contact footer__empty__contact"></div>
                    </Col>
                  </Row>
                </Col>
                <Col md="2" lg="2" className="order-md-2 d-sm-none d-md-block">
                  <div className="footer__contact">
                    <p className="font-weight-medium">London</p>
                    <p>
                      <a
                        href="mailto:hello@satalia.com"
                        title="hello@satalia.com"
                        rel="nofollow noreferrer"
                      >
                        hello@satalia.com
                      </a>
                    </p>
                    <br />
                    <p className="footer__contact-adress">
                      Greater London House,
                    </p>
                    <p className="footer__contact-adress">
                      180 Hampstead Road,
                    </p>
                    <p className="footer__contact-adress">London, NW1 7AW,</p>
                    <p className="footer__contact-adress">United Kingdom</p>
                    <a
                      className="learn-more"
                      href="https://goo.gl/maps/V3hmc4pjTVLb9GMU6"
                      target="blank"
                      rel="nofollow noreferrer"
                      title="Greater London House, 180 Hampstead Road, London, NW1 7AW, United Kingdom"
                    >
                      Get directions
                    </a>
                  </div>
                </Col>
                <Col md="2" lg="2" className="order-md-2 d-sm-none d-md-block">
                  <div className="footer__contact">
                    <p className="font-weight-medium">Kaunas</p>
                    <p>
                      <a
                        href="mailto:labas@satalia.com"
                        title="labas@satalia.com"
                        rel="nofollow noreferrer"
                      >
                        labas@satalia.com
                      </a>
                    </p>
                    <br />
                    <p className="footer__contact-adress">Jonavos g. 30,</p>
                    <p className="footer__contact-adress">Kaunas, LT-44262,</p>
                    <p className="footer__contact-adress">Lithuania</p>
                    <a
                      className="learn-more"
                      href="https://goo.gl/maps/AzzJj9BvPJR8aFKC9"
                      target="blank"
                      title="Jonavos g. 30, Kaunas, LT-44262, Lithuania"
                      rel="nofollow noreferrer"
                    >
                      Get directions
                    </a>
                  </div>
                </Col>
                <Col
                  md="2"
                  lg="2"
                  className="order-md-2 d-sm-none d-md-block"
                ></Col>
                <Col
                  sm="8"
                  md="6"
                  lg="8"
                  xl="8"
                  className="order-md-5 d-flex align-items-end"
                >
                  <p>
                    © 2008 - {new Date().getFullYear()} NPComplete Ltd (Trading
                    as Satalia). All Rights Reserved.
                    <br className="d-none d-lg-block" /> NPComplete Ltd is a
                    Company in England and Wales. Registered number: 06759250
                  </p>
                </Col>
                <Col
                  sm="2"
                  md="2"
                  lg="2"
                  className="footer__brand d-none d-lg-flex order-md-3 order-lg-3 "
                >
                  <Link to="/" title="Satalia">
                    <img
                      alt="satalia-logo"
                      src={
                        props.darkMode === true
                          ? data.light.publicURL
                          : data.default.publicURL
                      }
                    />
                  </Link>
                </Col>
                <Col
                  sm="4"
                  md="2"
                  lg="2"
                  className="footer__privacy order-md-6 mt-md-0 "
                >
                  <ul className="footer__links ">
                    <li className="footer__links-item">
                      <a
                        className="footer__links-link iubenda-nostyle no-brand iubenda-embed"
                        href="https://www.iubenda.com/privacy-policy/7785247"
                        title="Privacy Policy "
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="footer__links-item">
                      <a
                        className="footer__links-link iubenda-nostyle no-brand iubenda-embed"
                        href="https://www.iubenda.com/privacy-policy/7785247/cookie-policy"
                      >
                        Cookie Policy
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md="2" lg="2" className="d-none d-md-block order-md-7">
                  <div className="scroll-top">
                    <div className="scroll-top--text">Back to top</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
