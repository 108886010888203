import React, { Component } from "react"
import { Col } from "react-bootstrap"
import Menu from "./menu"
import $ from "jquery"
import { ILink } from "../model/Links"

export default class StickyMenu extends Component<{
  darkMode?: boolean
  links?: ILink[]
}> {
  componentDidMount() {
    $("#sticky-menu .dropdown-menu").hover(
      function() {
        $("#sticky-menu").addClass("dropdown-menu--show")
        let height = $(this).height()
        let dropdownHeight = $(this)
          .find(".dropdown-menu__items")
          .height()
        $(this).addClass("show")
        $(this).css("max-height", height + dropdownHeight + 30)
        $(this).height(height + dropdownHeight + 30)
      },
      function() {
        $("#sticky-menu").removeClass("dropdown-menu--show")
        $(this).css("max-height", 24)
        $(this).css("height", 0)
        $(this).removeClass("show")
      },
    )
  }
  render() {
    return (
      <Col lg="12" className="container d-none d-md-block">
        <Menu
          showLogo={true}
          darkMode={this.props.darkMode}
          id="sticky-menu"
          links={this.props.links}
        />
      </Col>
    )
  }
}
