import React, { Component } from "react"
import $ from "jquery"
export default class ScrollTop extends Component {
  state = {
    show: false,
  }
  componentDidMount() {
    let vm = this
    $(window).scroll(function() {
      const windowTop = $(window).scrollTop()
      const scroll = $(".scroll-top").offset().top
      const hideTrigger = $("footer").offset().top

      if ($(window).width() < 1024) {
        if (windowTop > 0) {
          if (vm.state.show === false)
            vm.setState({
              show: true,
            })
        } else
          vm.setState({
            show: false,
          })
        return
      }
      if (scroll > 0 && windowTop > 0) {
        if (scroll < hideTrigger) {
          if (vm.state.show === false)
            vm.setState({
              show: true,
            })
        } else {
          vm.setState({
            show: false,
          })
        }
      } else {
        if (vm.state.show === true)
          vm.setState({
            show: false,
          })
      }
    })
    $(".scroll-top").click(function() {
      window.scroll({
        behavior: "smooth",
        top: 0,
        left: 0,
      })
    })
  }
  render() {
    return (
      <div
        className={`scroll-top scroll-top__area  ${
          this.state.show === true ? "show" : ""
        }`}
      >
        <span>
          <i className="icon icon-chevron-up scroll-top__icon"></i>
        </span>
      </div>
    )
  }
}
