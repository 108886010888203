/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { SiteMetaQuery } from "../graphql"
import FontUrl1 from "../../static/fonts/untitled-sans-web-medium.woff2"
import FontUrl2 from "../../static/fonts/untitled-sans-web-regular.woff2"
import FontUrl3 from "../../static/fonts/untitled-sans-web-light.woff2"
type MetaProps = JSX.IntrinsicElements["meta"]

interface SEOProps {
  title: string
  twitterTitle?: string
  openGraphTitle?: string
  description: string
  twitterDescription?: string
  openGraphDescription?: string
  lang?: string
  meta?: MetaProps[]
}

function SEO({
  description,
  twitterDescription,
  openGraphDescription,
  lang = `en`,
  meta = [],
  title,
  twitterTitle,
  openGraphTitle,
}: SEOProps) {
  const { site }: SiteMetaQuery = useStaticQuery(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `,
  )
  const { pathname } = useLocation()
  const siteMetadata = site!.siteMetadata!
  const siteUrl = siteMetadata.siteUrl!
  const ogImage = siteMetadata.image!
  const metaDescription = description || siteMetadata.description!
  const metaTwitterDescription = twitterDescription || siteMetadata.description!
  const metaOpenGraphDescription =
    openGraphDescription || siteMetadata.description!
  const metaOpenGraphTitle = openGraphTitle || siteMetadata.title!
  const metaTwitterTitle = twitterTitle || siteMetadata.title!
  const constantMeta: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `author`,
      content: siteMetadata.author!,
    },
    {
      name: `publisher`,
      content: siteMetadata.title,
    },

    {
      name: "robots",
      content: "index, follow",
    },
    {
      property: `og:description`,
      content: metaOpenGraphDescription,
    },
    {
      property: `og:url`,
      content: `${siteUrl}${pathname}`,
    },
    {
      property: `og:title`,
      content: metaOpenGraphTitle,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `${siteMetadata.siteUrl + ogImage}`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author!,
    },
    {
      name: `twitter:title`,
      content: metaTwitterTitle,
    },
    {
      name: `twitter:site`,
      content: "@sataliasolves",
    },
    {
      name: `twitter:description`,
      content: metaTwitterDescription,
    },
    {
      httpEquiv: "X-UA-Compatible",
      content: "IE=edge,chrome=1",
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={constantMeta.concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={FontUrl1}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl3}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TH8D3CX');`}</script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-234734047-1"
      ></script>
      <script>
        window.dataLayer = window.dataLayer || []; function gtag() &#123;
        dataLayer.push(arguments); &#125; gtag('js', new Date()); gtag('config',
        'UA-234734047-1');
      </script>
      <script type="text/javascript">{`
          var _iub = _iub || [];
          _iub.csConfiguration = {"lang":"en","siteId":487318,"whitelabel":false,"cookiePolicyId":7785247, "banner":{ "applyStyles":false }};
        `}</script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        charSet="UTF-8"
        async
      ></script>
    </Helmet>
  )
}

export default SEO
