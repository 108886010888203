/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode, Component } from "react"
import Helmet from "react-helmet"
import { Row } from "react-bootstrap"
import { Header, Footer, StickyMenu, MobileMenu, Border, ScrollTop } from "./"
import $ from "jquery"
import "scroll-behavior-polyfill"
import SmoothScroll from "smooth-scroll"
import aos from "aos"
import { ILink } from "../model/Links"

interface LayoutProps {
  children: ReactNode
  step: number
  darkMode?: boolean
  isAdmin?: boolean
  page?: string
  links?: ILink[]
  smallHeader?: boolean
}

interface LayoutState {
  width: number
  height: number
}

export default class Layout extends Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  async componentDidMount() {
    if (!("scrollBehavior" in document.documentElement.style)) {
      await import("scroll-behavior-polyfill")
    }
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      SmoothScroll('a[href*="#"]', {
        offset: 70,
        easing: "Linear",
      })
    }
    this.updateWindowDimensions()
    $(".layout-border").addClass("show")
    window.addEventListener("resize", this.updateWindowDimensions)
    let vm = this
    let menu = $("#content-menu")
    if (menu.length > 0) {
      $(window).scroll(function() {
        const menuTrigger = menu.offset().top + menu.height()
        // set distance user needs to scroll before we start fadeIn
        if ($(this).scrollTop() > menuTrigger) {
          $(".sticky-header").addClass("sticky")
          if (vm.state.width > 1023) {
            $(".menu-open").addClass("d-md-none")
          }
        } else {
          if (vm.state.width > 1023) {
            $(".menu-open").removeClass("d-md-none")
          }
          $(".sticky-header").removeClass("sticky")
        }
      })
    }
    $(".menu-open").click(function() {
      $(".mobile-header").addClass("show")
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    return (
      <>
        <div
          scroll-behavior="smooth"
          className={
            this.props.darkMode === true
              ? "theme--dark" + " step-" + this.props.step
              : "theme--default" + " step-" + this.props.step
          }
        >
          <Helmet></Helmet>
          {!this.props.isAdmin ? (
            <Header
              step={this.props.step}
              darkMode={this.props.darkMode}
              smallHeader={this.props.smallHeader}
            />
          ) : null}
          <main>
            <Border position="absolute" />
            {!this.props.isAdmin
              ? [
                  <ScrollTop key="scroll-top" />,
                  <div className="menu-open d-lg-none" key="menu-open">
                    <button>Menu</button>
                  </div>,
                ]
              : null}

            {!this.props.isAdmin
              ? [
                  <Row
                    className="sticky-header justify-content-center d-none d-md-block"
                    key="sticky"
                  >
                    <StickyMenu
                      darkMode={this.props.darkMode}
                      links={this.props.links}
                    />
                  </Row>,
                  <div
                    className="mobile-header justify-content-center d-lg-none"
                    key="mobile"
                  >
                    <MobileMenu
                      darkMode={this.props.darkMode}
                      links={this.props.links}
                    />
                  </div>,
                ]
              : null}

            {this.props.children}
          </main>
          {!this.props.isAdmin ? (
            <Footer darkMode={this.props.darkMode} page={this.props.page} />
          ) : null}
        </div>
        {!this.props.isAdmin ? (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/25874273.js"
          ></script>
        ) : null}
      </>
    )
  }
}
