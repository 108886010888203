import { graphql, StaticQuery, Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import VideoPlayer from "./videoPlayer"
interface HeaderProps {
  step: number
  darkMode: boolean
  smallHeader?: boolean
}
const Header = ({ step, darkMode, smallHeader }: HeaderProps) => {
  const [headerClass, setHeaderClass] = useState("")

  useEffect(() => {
    const localStep = localStorage.getItem("step")
    setHeaderClass(
      step !== 1 && localStep === "1"
        ? "header position-relative header--large"
        : "header position-relative",
    )
    if (step === 1) {
      setTimeout(function() {
        setHeaderClass("header position-relative header--large")
      }, 200)
    } else {
      setTimeout(function() {
        const euHeader = smallHeader ? "header--eu" : ""
        setHeaderClass(`header position-relative header--small ${euHeader}`)
      }, 200)
    }
    localStorage.setItem("step", step.toString())
  }, [])
  return (
    <header className={headerClass}>
      <VideoPlayer step={step} darkMode={darkMode} />
      <Container className="position-absolute">
        <Row className="header-logo-area">
          <Col xs={6} lg="12" className="align-self-end">
            <StaticQuery
              query={graphql`
                query {
                  file(relativePath: { eq: "logomark.svg" }) {
                    publicURL
                  }
                }
              `}
              render={(data) => {
                return (
                  <Link to="/" title="Satalia">
                    <img alt="satalia-logo" src={data.file.publicURL} />
                  </Link>
                )
              }}
            />
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
