interface Video {
  [key: number]: {
    startTime: number
    endTime: number
    playbackRate: number
    image: string
  }
}

const videos: Video = {
  1: {
    startTime: 0,
    endTime: 12.41,
    playbackRate: 1,
    image: "step-1",
  },
  2: {
    startTime: 12.45,
    endTime: 23.98,
    playbackRate: 1,
    image: "step-2",
  },
  3: {
    startTime: 23.98,
    endTime: 29,
    playbackRate: 1,
    image: "step-3",
  },
}
export default videos
