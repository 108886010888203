interface ILink {
  label: string
  to: string
  isDropdown: boolean
  isExternal?: boolean
  class?: string
  dropdownLinks?: ILink[]
}

const MenuLinks: ILink[] = [
  {
    label: "Products",
    to: "/",
    isDropdown: true,
    class: "",
    dropdownLinks: [
      {
        label: "Satalia Delivery",
        to: "/delivery",
        isDropdown: false,
      },
      {
        label: "Satalia Field Service",
        to: "https://ondemand.satalia.com/",
        isDropdown: false,
        isExternal: true,
      },
      {
        label: "Satalia Workforce",
        to: "/workforce",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Services",
    to: "/",
    isDropdown: true,
    class: "",
    dropdownLinks: [
      {
        label: "Custom AI",
        to: "/custom-ai",
        isDropdown: false,
      },
      {
        label: "AI Strategy",
        to: "/ai-strategy",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Clients",
    to: "/clients",
    isDropdown: false,
    class: "",
  },
  {
    label: "Blog",
    to: "https://blog.satalia.com",
    isDropdown: false,
    class: "",
  },
  {
    label: "Careers",
    to: "/careers",
    isDropdown: false,
    class: "",
  },
  {
    label: "About",
    to: "/about",
    isDropdown: false,
    class: "",
  },
  {
    label: "Contact",
    to: "/contact",
    isDropdown: false,
    class: "contact",
  },
]

const SocialLinks: ILink[] = [
  {
    label: "Twitter",
    to: "https://twitter.com/sataliasolves",
    isDropdown: false,
    class: "",
  },
  {
    label: "Linkedin",
    to: "https://www.linkedin.com/company/satalia",
    isDropdown: false,
    class: "",
  },
  {
    label: "Facebook",
    to: "https://www.facebook.com/satalia.lithuania",
    isDropdown: false,
    class: "",
  },
]

const AdminLinks: ILink[] = [
  {
    label: "Products",
    to: "/",
    isDropdown: true,
    class: "",
    dropdownLinks: [
      {
        label: "Satalia Delivery",
        to: "/delivery",
        isDropdown: false,
      },
      {
        label: "Satalia Workforce",
        to: "/workforce",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Services",
    to: "/",
    isDropdown: true,
    class: "",
    dropdownLinks: [
      {
        label: "Custom AI",
        to: "/custom-ai",
        isDropdown: false,
      },
      {
        label: "AI Strategy",
        to: "/ai-strategy",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Clients",
    to: "/clients",
    isDropdown: false,
    class: "",
  },
  {
    label: "Careers",
    to: "/careers",
    isDropdown: false,
    class: "",
  },
  {
    label: "About",
    to: "/about",
    isDropdown: false,
    class: "",
  },
  {
    label: "Contact",
    to: "/contact",
    isDropdown: false,
    class: "contact",
  },
]

export { ILink, MenuLinks, SocialLinks, AdminLinks }
